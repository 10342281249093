<template>
  <div>
    <div v-html="htmlContent" class="html_content"></div>
  </div>
</template>

<script>
import {getNoticeById} from "@/api/sysMsg";

export default {
  name: "Book",
  data() {
    return {
      htmlContent: '',
      noticeId: '88',
    }
  },
  mounted() {
    this.getNoticeMsg();
  },
  methods: {
    getNoticeMsg() {
      if (this.noticeId) {
        getNoticeById({id: this.noticeId}).then((res) => {
          if (res.data.code === 200 && res.data.data.status === 'T') {
            this.htmlContent = res.data.data.contents;
          }
        })
      }
    },
  }
}
</script>
<style scoped lang="stylus">
.html_content {
  background: white;
  padding: 1rem 2rem;
  max-height: 85vh;
  overflow: auto;
  box-sizing: border-box;

  /deep/ img {
    max-width: 100%;
    height: auto;
    object-fit: contain;
  }
}
</style>
